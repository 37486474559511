$color_3: #006e52;
$color_4: #fff;
$background-color_3: rgba(0,0,0,.7);
$background-color_4: #000;
$background-color_5: #2d4f1e;
$border-color_1: currentColor currentColor currentColor transparent;
$border-radius: 5px;
$border-color: #cdcdcd;

@keyframes ld-cycle {
    0%,50%,to {
        animation-timing-function: cubic-bezier(.5, .5, .5, .5);
    }
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-webkit-keyframes ld-cycle {
    0%,50%,to {
        animation-timing-function: cubic-bezier(.5, .5, .5, .5);
    }
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.button:after, .button:not([disabled]):hover:after {
    box-shadow: none;
}

.th_subscription_section {
    width: 100%;
    display: inline-block;
    padding: 30px 0;
    box-sizing: border-box;
    div {
        box-sizing: border-box;
    }
    legend {
        box-sizing: border-box;
    }
    label {
        box-sizing: border-box;
    }
    select {
        box-sizing: border-box;
    }
    span {
        box-sizing: border-box;
    }
    input {
        box-sizing: border-box;
    }
    .th_sp_wf_cls {
        width: 100%;
        float: left;
    }
    .th_subscription_section_title {
        margin-bottom: 5px;
    }
    .th_subscription_section_outer {
        border-bottom: 1px solid #eee;
    }
    .th_subscription_fieldset {
        padding: 15px;
        border-left: 1px solid #eee;
        border-right: 1px solid #eee;
        border-top: 1px solid #eee;
    }
}
.th_subscription_fieldset {
    .th_subscription_group_label {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        align-items: center;
        line-height: normal !important;
        margin: 0 !important;
        padding: 0 !important;
        .th_subscription_fieldset_radio {
            width: auto;
            height: 15px;
            margin-right: 8px;
            min-height: inherit !important;
        }
        .th_subscription_fieldset_name {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            font-weight: normal !important;
        }
        .th_subscription_fieldset_discount_val {
            em {
                font-size: 12px;
                line-height: normal;
                font-style: normal;
                font-weight: bold;
                background-color: $background-color_5;
                color: $color_4;
                padding: 3px 5px;
                border-radius: 5px;
                margin: 0 0 0 3px;
            }
        }
        .th_subscription_fieldset_discount_min_price {
            font-size: 14px;
            line-height: 20px;
            margin-left: auto;
            padding-left: 10px;
            span {
                padding-left: 2px;
            }
        }
    }
    .th_subscription_spg_plan {
        padding: 15px 20px 0;
        .th_subscription_spg_plan_title {
            padding: 0 0 10px !important;
            margin: 0 !important;
            line-height: normal !important;
        }
        .th_subscription_spg_plan_select {
            width: 100%;
            min-height: inherit !important;
        }
    }
    .th_subscription_spg_plan.th_subscription_otp_empty_hide {
        display: none !important;
    }
}
.thsb_cp_section {
    width: 100%;
    display: inline-block;
    padding: 30px 15px;
    box-sizing: border-box;
    div {
        box-sizing: border-box;
    }
    p {
        box-sizing: border-box;
    }
    span {
        box-sizing: border-box;
    }
    a {
        box-sizing: border-box;
    }
    table {
        box-sizing: border-box;
    }
    thead {
        box-sizing: border-box;
    }
    tbody {
        box-sizing: border-box;
    }
    th {
        box-sizing: border-box;
    }
    td {
        box-sizing: border-box;
    }
    tr {
        box-sizing: border-box;
    }
    strong {
        box-sizing: border-box;
    }
    h1 {
        box-sizing: border-box;
    }
    h2 {
        box-sizing: border-box;
    }
    h3 {
        box-sizing: border-box;
    }
    .th_sp_wf_cls {
        width: 100%;
        float: left;
        margin: 0;
    }
    .thsb_cp_container {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        &:after {
            content: '';
            display: table;
            clear: both;
        }
    }
    .thsb_cp_page_title {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 40px;
        h1 {
            margin: 0 !important;
            padding: 0 15px 0 0 !important;
            line-height: normal;
        }
        .thsb_cp_back_btn {
            padding: 8px 15px;
        }
    }
}
.thsb_cp_inner {
    padding: 0;
    margin: 0;
}
.thsb_cp_subscription_tabs {
    padding: 15px 0;
    ul.thsb_cp_subscription_tab_ul {
        padding: 0;
        margin: 0;
        border-bottom: 1px solid #eee;
        list-style: none;
    }
    li.thsb_cp_subscription_tab_li {
        width: auto;
        float: left;
        margin: 0;
        padding: 0 15px;
        list-style-type: none;
        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
        }
        span {
            width: auto;
            display: inline-block;
            cursor: pointer;
            font-size: 15px;
            line-height: normal;
            margin: 0;
            padding: 0 2px 5px;
            margin-bottom: -1px;
            text-decoration: none;
        }
        span.thsb_cp_active_tab {
            border-bottom: 2px solid #008000;
            font-weight: 700;
        }
    }
    .thsb_cp_subscription_tab_contants {
        margin: 0;
        padding: 0;
    }
}
.thsb_cp_subscription_tab_contants {
    .thsb_cp_subscription_listing {
        display: none;
    }
    .thsb_cp_subscription_listing.thsb_cp_active_tab {
        display: block !important;
    }
    ul.thsb_cp_subscription_listing_ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    li.thsb_cp_subscription_listing_li {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 20px;
        padding: 15px 15px;
        border: 1px solid #eee;
        list-style-type: none;
        .thsb_cp_subscription_empty_list {
            width: auto;
            display: inline-block;
            font-size: 14px;
            line-height: normal;
            text-decoration: none;
        }
    }
    .thsb_cp_s_id {
        flex: 0 0 15%;
        max-width: 15%;
        padding: 0 12px;
        a {
            width: auto;
            display: inline-block;
            font-size: 15px;
            line-height: normal;
            text-align: center;
            text-decoration: none;
        }
    }
    .thsb_cp_s_product_details {
        flex: 0 0 35%;
        max-width: 35%;
        padding: 0 12px;
    }
    .thsb_cp_s_next_order {
        flex: 0 0 25%;
        max-width: 25%;
        padding: 0 12px;
    }
    .thsb_cp_s_manage_column {
        flex: 0 0 25%;
        max-width: 25%;
        padding: 0 12px;
    }
    .thsb_cp_s_product_details_inner {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        align-items: center;
        .thsb_cp_s_product_image {
            flex: 0 0 30%;
            max-width: 30%;
            padding-right: 15px;
            figure {
                margin: 0;
                padding: 0;
            }
            a {
                width: auto;
                float: left;
                text-decoration: none;
                svg {
                    width: 100%;
                    float: left;
                }
            }
            img {
                width: auto;
                float: left;
                max-width: 100%;
            }
        }
        .thsb_cp_s_product_detail {
            flex: 0 0 70%;
            max-width: 70%;
            padding: 0;
        }
        .thsb_cp_s_product_title {
            width: 100%;
            float: left;
            font-size: 18px;
            line-height: normal;
            margin: 0;
            padding: 0;
            font-weight: 700;
            word-break: break-word;
            a {
                width: auto;
                float: left;
                font-size: 18px;
                line-height: normal;
                text-decoration: none;
            }
        }
        .thsb_cp_s_product_qty {
            width: 100%;
            float: left;
            font-size: 14px;
            line-height: normal;
            margin: 0;
            padding: 5px 0 0;
            span {
                width: auto;
                display: inline-block;
                vertical-align: middle;
                font-size: 14px;
                line-height: normal;
            }
        }
        .thsb_cp_s_product_price {
            width: 100%;
            float: left;
            font-size: 14px;
            line-height: normal;
            margin: 0;
            padding-top: 5px;
            span {
                width: auto;
                display: inline-block;
                vertical-align: middle;
                font-size: 14px;
                line-height: normal;
            }
        }
    }
    .thsb_cp_s_next_order_text {
        width: 100%;
        float: left;
        font-size: 15px;
        line-height: normal;
        margin: 0;
        padding: 0;
    }
    .thsb_cp_s_next_order_date {
        width: 100%;
        float: left;
        font-size: 18px;
        line-height: normal;
        margin: 0;
        padding: 5px 0 0;
        font-weight: 700;
    }
    .thsb_cp_s_manage_sub_btn {
        width: 100%;
        padding: 8px 15px;
        font-size: 14px;
        line-height: normal;
    }
}
.thsb_cp_detail_section {
    width: 100%;
    display: inline-block;
    padding: 30px 15px;
    div {
        box-sizing: border-box;
    }
    p {
        box-sizing: border-box;
    }
    span {
        box-sizing: border-box;
    }
    a {
        box-sizing: border-box;
    }
    table {
        box-sizing: border-box;
    }
    thead {
        box-sizing: border-box;
    }
    tbody {
        box-sizing: border-box;
    }
    th {
        box-sizing: border-box;
    }
    td {
        box-sizing: border-box;
    }
    tr {
        box-sizing: border-box;
    }
    strong {
        box-sizing: border-box;
    }
    h1 {
        box-sizing: border-box;
    }
    h2 {
        box-sizing: border-box;
    }
    h3 {
        box-sizing: border-box;
    }
    .th_sp_wf_cls {
        width: 100%;
        float: left;
        margin: 0;
    }
    .thsb_cp_detail_container {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        &:after {
            content: '';
            display: table;
            clear: both;
        }
    }
    .thsb_cp_detail_page_title {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 40px;
        h1 {
            margin: 0 !important;
            padding: 0 15px 0 0 !important;
            line-height: normal;
        }
        a {
            margin: 10px 0;
            text-decoration: none;
            padding: 8px 15px;
            border-radius: $border-radius;
        }
    }
    .thsb_cp_detail_inner {
        padding: 0 0 20px 0;
    }
    .thsb_cp_detail_row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 0;
        margin: 0 -15px;
    }
    .thsb_cp_detail_s_details {
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
        padding: 0 15px;
    }
    .thsb_cp_detail_s_products {
        flex: 0 0 66.6666%;
        max-width: 66.6666%;
        padding: 0 15px;
    }
    .thsb_cp_ajax_btn_cls {
        position: relative;
        padding: 8px 15px;
        border-radius: $border-radius;
        span {
            width: auto;
            float: left;
            word-break: break-word;
            white-space: break-spaces;
            line-height: normal;
        }
    }
    .thsb_cp_ajax_btn_cls.thsb_cp_ajax_btn_cls_loading {
        span.thsb_cp_btn_text {
            opacity: 0;
        }
        em.thsb_cp_btn_loader {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -8px 0 0 -8px;
            opacity: 1;
            transition-timing-function: ease-in;
            -webkit-animation: ld-cycle 1s infinite linear;
            animation: ld-cycle 1s infinite linear;
            &::after {
                width: 100%;
                height: 100%;
                position: absolute;
                margin: auto;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                content: " ";
                display: inline-block;
                background: center center no-repeat;
                background-size: cover;
                -webkit-background-clip: padding-box;
                box-sizing: border-box;
                border-width: 3px;
                border-color: $border-color_1;
                border-radius: 50%;
                border-style: solid;
            }
        }
    }
    .thsb_cp_detail_csa_title {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
    .thsb_cp_detail_billing_table {
        a {
            width: 100%;
            text-decoration: none;
        }
    }
}
.thsb_cp_detail_s_details {
    .thsb_cp_detail_s_title {
        margin: 0 0 10px 0;
        padding: 0;
        h4 {
            width: 100%;
            float: left;
            font-size: 20px;
            line-height: 30px;
            margin: 0;
            padding: 0;
            font-weight: 700;
        }
    }
    .thsb_cp_detail_s_next_order {
        margin: 0 0 15px;
        padding: 15px;
        border: 1px solid $border-color;
    }
    .thsb_cp_detail_s_inner_title {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        align-items: center;
        .thsb_cp_detail_s_inner_title_text {
            -ms-flex-preferred-size: 0;
            flex-basis: 0;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            max-width: 100%;
            margin: 0;
            padding: 0;
            font-size: 15px;
            line-height: normal;
        }
        .thsb_cp_detail_s_inner_title_edit {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: auto;
            margin: 0;
            padding: 0 0 0 10px;
            font-size: 15px;
            line-height: normal;
            font-weight: 700;
            text-align: right;
            text-decoration: underline;
        }
    }
    .thsb_cp_detail_s_no_date {
        margin: 0;
        padding: 15px 0;
        h4 {
            width: 100%;
            float: left;
            font-size: 22px;
            line-height: normal;
            margin: 0;
            padding: 0;
            font-weight: 700;
        }
    }
    .thsb_cp_detail_s_no_btns {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 0;
        margin: 0 -5px;
        width: calc(100% + 10px);
        border-radius: $border-radius;
        a {
            width: 100%;
            font-size: 12px;
            line-height: normal;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            padding: 8px 15px;
            span {
                width: auto;
                float: left;
                word-break: break-word;
                white-space: break-spaces;
            }
        }
        em {
            position: absolute;
        }
    }
    .thsb_cp_detail_s_no_btn_attempt {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 5px;
        margin: 0 0 10px;
    }
    .thsb_cp_detail_s_no_btn_skip {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 5px;
        margin: 0 0 10px;
    }
    .thsb_cp_detail_s_no_btn_pause {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 5px;
    }
    .thsb_cp_detail_s_no_btn_cancel {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 5px;
    }
    .thsb_cp_detail_s_dev_every {
        margin: 20px 0 0;
        padding: 0;
    }
    .thsb_cp_detail_s_de_days {
        margin: 0;
        padding: 10px 0 0;
        h4 {
            width: 100%;
            float: left;
            font-size: 22px;
            line-height: normal;
            margin: 0;
            padding: 0;
            font-weight: 700;
        }
    }
    .thsb_cp_detail_s_pay_method {
        margin: 0 0 15px 0;
        padding: 15px;
        border: 1px solid $border-color;
    }
    .thsb_cp_detail_s_pm_card {
        margin: 0;
        padding: 15px 0 0;
    }
    .thsb_cp_detail_s_pm_card_detail {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        align-items: center;
        em {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: auto;
            max-width: none;
            line-height: 0;
            margin: 0;
            svg {
                width: 30px;
            }
        }
        span {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: auto;
            max-width: none;
            font-size: 14px;
            line-height: normal;
            padding-left: 10px;
            margin: 0;
        }
    }
    .thsb_cp_detail_s_discount {
        margin: 0 0 15px 0;
        padding: 15px;
        border: 1px solid $border-color;
    }
    .thsb_cp_detail_s_discount_content {
        margin: 0;
        padding: 15px 0 0;
        p {
            width: 100%;
            float: left;
            margin: 0;
            padding: 0 0 8px;
            span.thsb_cp_detail_s_discount_left {
                width: auto;
                float: left;
                font-size: 14px;
                line-height: normal;
                margin: 0;
                padding: 0;
                text-align: left;
            }
            span.thsb_cp_detail_s_discount_right {
                width: auto;
                float: right;
                font-size: 14px;
                line-height: normal;
                margin: 0;
                padding: 0;
                text-align: right;
            }
        }
    }
    .thsb_cp_detail_s_discount_div {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    .thsb_cp_detail_s_discount_form {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .thsb_cp_detail_s_discount_input {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
        line-height: 0;
        margin: 0;
        padding: 5px 15px;
        border-radius: 8px;
    }
    #thsb_cp_detail_s_discount_btn {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none;
        line-height: 0;
        margin: 0 0 0 10px;
        padding: 8px 15px;
    }
    .thsb_cp_detail_s_bill_add {
        margin: 0 0 15px 0;
        padding: 15px;
        border: 1px solid $border-color;
    }
    .thsb_cp_detail_s_ba_days {
        margin: 0;
        padding: 15px 0 0;
        p {
            width: 100%;
            float: left;
            font-size: 13px;
            line-height: normal;
            margin: 0 0 5px;
            padding: 0;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .thsb_cp_detail_s_ship_add {
        margin: 0;
        padding: 15px;
        border: 1px solid $border-color;
    }
    .thsb_cp_detail_s_sa_days {
        margin: 0;
        padding: 15px 0 0;
        p {
            width: 100%;
            float: left;
            font-size: 13px;
            line-height: normal;
            margin: 0 0 5px;
            padding: 0;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
.thsb_cp_detail_s_products {
    .thsb_cp_detail_s_p_title {
        margin: 0 0 10px 0;
        padding: 0;
        h4 {
            width: 100%;
            float: left;
            font-size: 20px;
            line-height: 30px;
            margin: 0;
            padding: 0;
            font-weight: 700;
            text-transform: capitalize;
        }
    }
    .thsb_cp_detail_s_product_details {
        height: calc(100% - 15px);
        border: 1px solid $border-color;
    }
    .thsb_cp_detail_sp_inner {
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        align-items: initial;
        margin: 0;
        padding: 20px;
        align-content: flex-start;
    }
    .thsb_cp_detail_sp_grid_section {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .thsb_cp_detail_sp_grid_section_title {
        margin: 0;
        padding: 0 0 20px;
        h4 {
            font-size: 16px;
            line-height: normal;
            margin: 0;
            padding: 0;
        }
    }
    .thsb_cp_detail_sp_inner_grid {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: 0 -10px;
        padding: 0 0 20px;
        &:last-child {
            padding-bottom: 0;
        }
    }
    .thsb_cp_detail_sp_image {
        flex: 0 0 130px;
        max-width: 130px;
        padding: 0 10px;
        margin: 0;
        figure {
            margin: 0;
            padding: 0;
        }
        a {
            width: 100%;
            float: left;
            text-decoration: none;
            svg {
                width: 100%;
                float: left;
            }
        }
        img {
            width: auto;
            float: left;
            max-width: 100%;
            border-radius: $border-radius;
            height: 100px;
            object-fit: cover;
        }
    }
    .thsb_cp_detail_sp_detail {
        flex: 0 0 calc(100% - 135px);
        max-width: none;
        padding: 0 10px;
    }
    .thsb_cp_detail_sp_detail_inner {
        margin: 0;
        padding: 0;
        .thsb_cp_detail_sp_detail_title {
            font-size: 16px;
            line-height: normal;
            font-weight: 700;
            margin: 0;
            padding: 0 0 2px;
        }
        .thsb_cp_detail_sp_name {
            font-size: 14px;
            line-height: normal;
            margin: 0;
        }
        .thsb_cp_detail_sp_name.one_time_purchase{
            padding: 5px 5px;
            border-radius: 5px;
            font-weight: 600;
            font-size: 12px;
            box-shadow: 0 2px 5px rgba(0,0,0,.25);
            background: #d1d1d1;
            color: black;
            width: auto;
        }
        .thsb_cp_detail_sp_detail_qty {
            font-size: 14px;
            line-height: normal;
            margin: 0;
            padding: 5px 0 0;
        }
        .thsb_cp_detail_sp_detail_price {
            font-size: 14px;
            line-height: normal;
            margin: 0;
            padding: 5px 0 0;
        }
        .thsb_cp_detail_sp_detail_update {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            margin: 0 -5px;
            align-items: normal;
            padding: 10px 0 0;
            width: calc(100% + 10px);
        }
        .thsb_cp_detail_sp_qty_update {
            flex: 0 0 150px;
            max-width: 150px;
            padding: 0 5px;
            margin: 0;
        }
        .thsb_cp_detail_sp_btn_update {
            flex: 0 0 auto;
            max-width: none;
            padding: 0 5px;
            margin: 0;
        }
        .thsb_cp_detail_sp_btn_remove {
            flex: 0 0 auto;
            max-width: none;
            padding: 0 5px;
            margin: 0;
        }
        .thsb_cp_detail_sp_qty_update_inner {
            height: 100%;
            margin: 0;
            padding: 0;
            position: relative;
            background-color: #d4d4d4;
            border-radius: $border-radius;
        }
        .thsb_cp_detail_sp_qty_btns {
            position: absolute;
            top: 0;
            height: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            align-items: center;
            padding: 0 15px;
            cursor: pointer;
            z-index: 9;
            svg {
                width: 10px;
                height: 10px;
            }
        }
        .thsb_cp_detail_sp_update_qty_btn {
            font-size: 12px;
            line-height: inherit;
            padding: 8px 15px;
            cursor: pointer;
        }
        .thsb_cp_detail_sp_remove_qty_btn {
            font-size: 12px;
            line-height: inherit;
            padding: 8px 15px;
            cursor: pointer;
        }
        .thsb_cp_detail_sp_qty_minus {
            left: 0;
        }
        .thsb_cp_detail_sp_qty_plus {
            right: 0;
        }
        .thsb_cp_detail_sp_qty_val {
            width: 100%;
            height: 100%;
            background-color: transparent !important;
            border: none;
            text-align: center;
            min-width: auto;
            max-width: none;
            min-height: auto;
            max-height: none;
            padding: 5px 0;
            &:focus {
                outline: none;
                border: none;
            }
        }
        .thsb_cp_detail_sp_btn_product_swap {
            flex: 0 0 auto;
            max-width: none;
            padding: 0 5px;
            margin: 0;
        }
        .thsb_cp_detail_sp_swap_product_btn {
            font-size: 12px;
            line-height: inherit;
            padding: 8px 15px;
            cursor: pointer;
        }
    }
    .thsb_cp_detail_s_summery {
        margin: 0;
        padding: 20px 0 0;
        margin-top: auto;
    }
    .thsb_cp_detail_s_summery_inner {
        margin: 0;
        padding: 20px 0 0;
        border-top: 1px solid $border-color;
    }
    .thsb_cp_detail_s_summery_content {
        width: 100%;
        padding: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: flex-end;
    }
    .thsb_cp_detail_s_product_shipping {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        align-items: center;
        padding: 0 0 10px 0;
        margin: 0;
    }
    .thsb_cp_detail_s_product_shipping_text {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
        margin: 0;
        padding: 0 10px 0 0;
        font-size: 16px;
        line-height: normal;
    }
    .thsb_cp_detail_s_product_shipping_value {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        margin: 0;
        padding: 0;
        font-size: 16px;
        line-height: normal;
        font-weight: 700;
        text-align: right;
    }
    .thsb_cp_detail_s_product_total_price {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        margin: 0;
        padding: 0;
        font-size: 16px;
        line-height: normal;
        font-weight: 700;
        text-align: right;
    }
    .thsb_cp_detail_s_product_status {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        align-items: center;
        padding: 0 0 10px 0;
        margin: 0;
    }
    .thsb_cp_detail_s_product_status_text {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
        margin: 0;
        padding: 0 10px 0 0;
        font-size: 16px;
        line-height: normal;
    }
    .thsb_cp_detail_s_product_status_value {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        margin: 0;
        padding: 0;
        font-size: 16px;
        line-height: normal;
        font-weight: 700;
        text-align: right;
    }
    .thsb_cp_detail_s_billing_attempts {
        margin: 0;
        padding: 0;
    }
    .thsb_cp_detail_s_ba_title {
        margin: 0;
        padding: 0 0 10px;
        h4 {
            font-size: 16px;
            line-height: normal;
            margin: 0;
            padding: 0;
        }
    }
    .thsb_cp_detail_s_ba_ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            align-items: center;
            padding: 0 0 10px;
            margin: 0 -15px;
            list-style-type: none;
            .thsb_cp_detail_s_ba_col {
                &:nth-child(2) {
                    text-align: center;
                }
                &:last-child {
                    text-align: right;
                }
            }
            span {
                font-size: 14px;
                line-height: normal;
                margin: 0;
                padding: 0;
            }
            &:last-child {
                padding-bottom: 0;
            }
        }
        .thsb_cp_detail_s_ba_col {
            flex: 0 0 20%;
            max-width: 20%;
            padding: 0 15px;
        }
        li.thsb_cp_detail_s_ba_head {
            span {
                font-weight: 700;
            }
        }
        li.thsb_cp_detail_s_ba_body {
            padding-bottom: 5px;
        }
    }
    .thsb_load_more_div {
        text-align: center;
        margin-top: 25px;
        .thsb_load_more_btn {
            letter-spacing: 1px;
            font-size: 15px;
        }
    }
}
.thsb_csa_model_section {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -999999999999;
    opacity: 0;
    -webkit-transition: all 0.7s ease-out;
    -moz-transition: all 0.7s ease-out;
    transition: all 0.7s ease-out;
    left: 0;
    top: 0;
    background-color: $background-color_3;
    overflow-y: scroll;
    .thsb_csa_model_outer {
        width: 100%;
        max-width: 1000px;
        overflow-y: auto;
        background: 0 0;
        padding: 20px 15px 50px;
        margin: 0 auto;
        position: absolute;
        top: 80px;
        left: 0;
        right: 0;
    }
    .thsb_csa_model_inner {
        width: 100%;
        float: left;
        position: relative;
        background: #fff;
        border-radius: 8px;
    }
    .thsb_csa_model_content {
        width: 100%;
        float: left;
        padding: 20px;
    }
    .thsb_csa_model_title {
        width: 100%;
        float: left;
        margin: 0;
        padding: 0 0 30px 0;
        h3 {
            margin: 0;
            padding: 0;
        }
    }
    .thsb_csa_model_form {
        width: 100%;
        float: left;
        fieldset {
            width: 100%;
            float: left;
            margin: 0;
            padding: 0 0 15px;
            border: none;
        }
        .thsb_csa_model_label_cls {
            width: 100%;
            float: left;
            margin: 0;
            padding: 0 0 5px 0;
        }
        .thsb_csa_model_input_cls {
            width: 100%;
            float: left;
        }
    }
    .thsb_csa_submit_form {
        width: 100%;
        float: left;
        margin: 0;
        padding: 10px 0 0 0;
        .thsb_csa_submit_form_btn {
            margin: 0 !important;
        }
    }
    .thsb_csa_model_close_btn {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        &:before {
            position: absolute;
            left: 9px;
            content: ' ';
            height: 20px;
            width: 2px;
            background-color: $background-color_4;
            transform: rotate(45deg);
        }
        &:after {
            position: absolute;
            left: 9px;
            content: ' ';
            height: 20px;
            width: 2px;
            background-color: $background-color_4;
            transform: rotate(-45deg);
        }
    }
}
.thsb_csa_model_section.thsb_csa_model_show {
    opacity: 1;
    z-index: 999999999999;
}
.thsb_cp_detail_ajax_msg {
    p {
        width: 100%;
        float: left;
        margin: 0;
        padding: 10px 0 0;
    }
    p.thsb_cp_detail_ajax_success {
        color: #4BB543;
    }
    p.thsb_cp_detail_ajax_error {
        color: #FF3333;
    }
}
.thsb_pp_order_scheduled_change_alert {
    p.thsb_cp_detail_ajax_success {
        padding: 0 15px;
    }
    p.thsb_cp_detail_ajax_error {
        padding: 0 15px;
    }
}
.thsb_cp_change_date_model {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -999999999999;
    opacity: 0;
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    left: 0;
    top: 0;
    background-color: $background-color_3;
    overflow-y: scroll;
    .thsb_cp_change_date_outer {
        width: 100%;
        max-width: 500px;
        overflow-y: auto;
        background: 0 0;
        padding: 20px 15px 50px;
        margin: 0 auto;
        position: absolute;
        top: 80px;
        left: 0;
        right: 0;
    }
    .thsb_cp_change_date_inner {
        width: 100%;
        float: left;
        position: relative;
        background: #fff;
        border-radius: 8px;
    }
    .thsb_cp_change_date_close_btn {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        &:before {
            position: absolute;
            left: 9px;
            content: ' ';
            height: 20px;
            width: 2px;
            background-color: $background-color_4;
            transform: rotate(45deg);
        }
        &:after {
            position: absolute;
            left: 9px;
            content: ' ';
            height: 20px;
            width: 2px;
            background-color: $background-color_4;
            transform: rotate(-45deg);
        }
    }
    .thsb_cp_change_date_title {
        width: 100%;
        float: left;
        margin: 0;
        padding: 0 0 30px 0;
        h3 {
            margin: 0;
            padding: 0;
        }
    }
    .thsb_cp_change_date_content {
        width: 100%;
        float: left;
        padding: 20px;
    }
    .thsb_cp_change_date_form {
        width: 100%;
        float: left;
        fieldset {
            width: 100%;
            float: left;
            margin: 0;
            padding: 0 0 15px;
            border: none;
        }
        .thsb_cp_change_date_label_cls {
            width: 100%;
            float: left;
            margin: 0;
            padding: 0 0 5px 0;
        }
        .thsb_cp_change_date_input_cls {
            width: 100%;
            float: left;
        }
        fieldset.thsb_cp_change_half_fieldset {
            .thsb_cp_change_date_half_padding {
                width: 50%;
                float: left;
                &:nth-child(odd) {
                    padding-left: 10px;
                }
                &:nth-child(even) {
                    padding-right: 10px;
                }
            }
        }
        .thsb_cp_change_date_select_cls {
            width: 100%;
            float: left;
        }
    }
    .thsb_cp_change_date_submit_form {
        width: 100%;
        float: left;
        margin: 0;
        padding: 10px 0 0 0;
        .thsb_cp_change_date_submit_form_btn {
            margin: 0 !important;
        }
    }
}
.thsb_cp_change_date_model.thsb_csa_model_show {
    opacity: 1;
    z-index: 999999999999;
}
.thsb_cp_cancel_reason_model {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -999999999999;
    opacity: 0;
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    left: 0;
    top: 0;
    background-color: $background-color_3;
    overflow-y: scroll;
    .thsb_cp_cancel_reason_outer {
        width: 100%;
        max-width: 650px;
        overflow-y: auto;
        background: 0 0;
        padding: 20px 15px 50px;
        margin: 0 auto;
        position: absolute;
        top: 80px;
        left: 0;
        right: 0;
    }
    .thsb_cp_cancel_reason_inner {
        width: 100%;
        float: left;
        position: relative;
        background: #fff;
    }
    .thsb_cp_cancel_reason_close_btn {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        &:before {
            position: absolute;
            left: 9px;
            content: ' ';
            height: 20px;
            width: 2px;
            background-color: $background-color_4;
            transform: rotate(45deg);
        }
        &:after {
            position: absolute;
            left: 9px;
            content: ' ';
            height: 20px;
            width: 2px;
            background-color: $background-color_4;
            transform: rotate(-45deg);
        }
    }
    .thsb_cp_cancel_reason_title {
        width: 100%;
        float: left;
        margin: 0;
        padding: 0 0 20px 0;
        h3 {
            margin: 0;
            padding: 0;
        }
    }
    .thsb_cp_cancel_reason_content {
        width: 100%;
        float: left;
        padding: 20px;
    }
    .thsb_cp_cancel_reason_form {
        width: 100%;
        float: left;
        fieldset {
            width: 100%;
            float: left;
            margin: 0;
            padding: 0 0 5px;
            border: none;
        }
        .thsb_cp_cancel_reason__label_cls {
            width: auto;
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            padding: 0;
            font-size: 14px;
            line-height: 18px;
        }
        .thsb_cp_cancel_reason_input_cls {
            display: inline-block;
            vertical-align: middle;
            margin-right: 3px;
            line-height: 18px;
        }
        .thsb_cp_change_date_select_cls {
            width: 100%;
            float: left;
        }
    }
    .thsb_cp_cancel_reason_submit_form {
        width: 100%;
        float: left;
        margin: 0;
        padding: 10px 0 0 0;
        .thsb_cp_cancel_reason_submit_form_btn {
            margin: 0 !important;
        }
    }
}
.thsb_cp_cancel_reason_model.thsb_cr_model_show {
    opacity: 1;
    z-index: 999999999999;
}
.thsb_pp_order_scheduled {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    .thsb_pp_order_scheduled_title {
        padding: 20px 0 0;
        border-top: 1px solid #eee;
        h4 {
            font-size: 16px;
            line-height: normal;
            margin: 0;
            padding: 0;
        }
    }
    .thsb_pp_order_scheduled_ul {
        margin: 0;
        padding: 10px 0 0;
        list-style: none;
        li {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            align-items: center;
            padding: 0 0 10px;
            margin: 0 -15px;
            list-style-type: none;
        }
        .thsb_pp_order_scheduled_col_small {
            flex: 0 0 25%;
            max-width: 25%;
            padding: 0 15px;
        }
        .thsb_pp_order_scheduled_col_big {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 15px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            justify-content: flex-end;
        }
        .thsb_pp_order_scheduled_col {
            span {
                font-size: 14px;
                line-height: normal;
                margin: 0;
                padding: 0;
            }
        }
        .thsb_pp_order_scheduled_head {
            span {
                font-weight: 700;
            }
        }
        .thsb_pp_order_scheduled_btns {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            margin: 0 -5px;
            align-items: center;
        }
        .thsb_pp_order_scheduled_single_btn {
            flex: 0 0 auto;
            max-width: none;
            padding: 0 5px;
            margin: 0;
            a {
                span {
                    font-size: 12px;
                }
            }
        }
    }
}
.thsb_pp_order_fulfilled {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    .thsb_pp_order_fulfilled_title {
        padding: 20px 0 0;
        margin: 10px 0 0;
        border-top: 1px solid $border-color;
        h4 {
            font-size: 16px;
            line-height: normal;
            margin: 0;
            padding: 0;
        }
    }
    .thsb_pp_order_fulfilled_ul {
        margin: 0;
        padding: 10px 0 0;
        list-style: none;
        li {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            align-items: center;
            padding: 0 0 10px;
            margin: 0 -15px;
            list-style-type: none;
            .thsb_pp_order_fulfilled_col {
                &:nth-child(2) {
                    text-align: center;
                }
                &:last-child {
                    text-align: right;
                }
            }
        }
        .thsb_pp_order_fulfilled_col {
            flex: 0 0 33.3333%;
            max-width: 33.3333%;
            padding: 0 15px;
            span {
                font-size: 14px;
                line-height: normal;
                margin: 0;
                padding: 0;
            }
        }
        .thsb_pp_order_fulfilled_head {
            span {
                font-weight: 700;
            }
        }
    }
}
.th_subscription_radio_section {
    width: 100%;
    display: inline-block;
    margin: 15px 0;
    input[type='radio'] {
        display: none !important;
    }
    .th_subscription_section_title {
        width: 100%;
        display: inline-block;
        font-size: 18px;
        line-height: 22px;
        margin: 0 0 5px 0;
        padding: 0;
    }
    .th_subscription_radio_section_outer {
        margin: 0;
        padding: 15px;
        border: 1px solid #eee;
    }
    .th_subscription_radio_plans {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: 0 -5px;
        padding: 0;
    }
    .th_subscription_radio_fieldset {
        width: 100%;
        -ms-flex-preferred-size: 0;
        flex-basis: revert;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
        padding: 0;
        margin: 0 0 10px;
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
    .th_subscription_radio_label_outer {
        width: 100%;
        height: auto;
        display: flex;
        text-align: center;
        label {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: left;
            margin: 0;
            font-size: 14px;
            line-height: normal;
            padding: 10px 15px;
            border: 1px solid #eee;
        }
        input {
            &:checked {
                + {
                    label {
                        color: $color_3;
                        border-color: #006e52;
                    }
                }
            }
        }
        .th_subscription_radio_fieldset_discount_min_price {
            font-size: 14px;
            line-height: normal;
            margin-left: auto;
            span {
                padding-left: 3px;
            }
        }
        .th_subscription_radio_fieldset_discount_val {
            em {
                font-size: 12px;
                line-height: normal;
                font-style: normal;
                font-weight: bold;
                background-color: $background-color_5;
                color: $color_4;
                padding: 3px 5px;
                border-radius: 5px;
                margin: 0 0 0 3px;
            }
        }
    }
    .th_subscription_radio_interval {
        width: 100%;
        margin: 15px 0 0;
        padding: 0;
        line-height: 0;
    }
    .th_subscription_radio_spg_plan_label {
        width: 100%;
        display: inline-block;
        margin: 0 0 10px;
        padding: 0;
    }
    .th_subscription_radio_spg_plan_label_title {
        width: 100%;
        float: left;
        margin: 0;
        padding: 0;
        font-size: 14px;
        line-height: normal;
    }
    .th_subscription_radio_spg_plan_detail {
        width: 100%;
        height: 100%;
        display: flex;
        margin: 0;
        padding: 0;
        &:last-child {
            .th_subscription_radio_spg_plan_inner_label {
                margin: 0 !important;
            }
        }
        .th_subscription_radio_checked {
            display: none;
        }
        input {
            &:checked {
                + {
                    label {
                        .th_subscription_radio_checked {
                            display: block;
                            fill: #006e52;
                        }
                        .th_subscription_radio_non_checked {
                            display: none;
                        }
                        color: $color_3;
                    }
                }
            }
        }
    }
    .th_subscription_radio_spg_plan_inner_label {
        width: 100%;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        align-items: center;
        margin: 0 0 12px 0;
        padding: 0;
    }
    .th_subscription_radio_spg_plan_svg {
        flex: 0 0 auto;
        max-width: none;
        line-height: 20px;
        padding: 0 10px 0 0;
        margin: 0;
        svg {
            width: 16px;
            height: 16px;
            float: left;
        }
    }
    .th_subscription_radio_spg_plan_title {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
        padding: 0;
        margin: 0;
        font-size: 14px;
        line-height: 20px;
    }
    .th_subscription_radio_spg_plan_price_delivery {
        flex: 0 0 auto;
        max-width: none;
        margin: 0;
        padding: 0 0 0 10px;
        line-height: 20px;
        em {
            width: auto;
            float: left;
            font-size: 14px;
            line-height: 20px;
            font-style: normal !important;
        }
        .th_subscription_radio_spg_plan_separate {
            padding: 0 2px;
        }
    }
}
.thsb_subscription_description_section {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0;
    padding: 10px 0 0;
    position: relative;
    .thsb_subscription_description_inner {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        align-items: center;
        margin: 0;
        padding: 0;
        cursor: pointer;
    }
    .thsb_subscription_description_logo {
        width: auto;
        flex: 0 0 auto;
        margin: 0 5px 0 0;
        svg {
            width: 22px;
            height: 22px;
            float: left;
            fill: #006e52;
        }
    }
    .thsb_subscription_description_text {
        width: auto;
        flex: 0 0 auto;
        margin: 0;
        padding: 0;
        p {
            width: 100%;
            float: left;
            margin: 0;
            padding: 0;
            font-size: 15px;
            line-height: normal;
        }
    }
    .thsb_subscription_description_tooltip {
        visibility: hidden;
        width: 100%;
        color: $color_4;
        background: #006e52;
        max-width: 250px;
        position: absolute;
        z-index: -9;
        top: calc(100% + 5px);
        left: 0;
        margin: 0;
        padding: 10px;
        &:after {
            content: '';
            width: 0px;
            height: 0px;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid #006e52;
            position: absolute;
            top: -6px;
            left: 5px;
        }
        p {
            margin: 0 0 10px 0;
            color: $color_4;
            font-size: 14px;
            &:last-child {
                margin: 0;
            }
        }
    }
    &:hover {
        .thsb_subscription_description_tooltip {
            visibility: visible;
            z-index: 9;
        }
    }
}
input.th_subscription_fieldset_radio[type=radio] {
    -webkit-appearance: radio;
}

.thsb_cp_add_item_as_model_close_btn {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 15px;
    height: 15px;
    cursor: pointer;
    padding: 5px 0px 0 20px;
    &:before {
        position: absolute;
        left: 9px;
        content: ' ';
        height: 15px;
        width: 2px;
        background-color: $background-color_4;
        transform: rotate(45deg);
    }
    &:after {
        position: absolute;
        left: 9px;
        content: ' ';
        height: 15px;
        width: 2px;
        background-color: $background-color_4;
        transform: rotate(-45deg);
    }
}

.thsb_cp_add_item_as_model_open, .thsb_cp_add_product_model_open, .thsb_cp_swap_product_model_open{
    visibility: hidden;
}

.thsb_csa_swap_model_show {
    visibility: visible;
}

.thsb_cp_swap_product_model {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -999999999999;
    opacity: 0;
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    left: 0;
    top: 0;
    background-color: $background-color_3;
    overflow-y: scroll;
    .th_sp_wf_cls {
        width: 100%;
        float: left;
    }
    .thsb_cp_swap_product_model_outer {
        width: 100%;
        max-width: 800px;
        overflow-y: auto;
        background: 0 0;
        padding: 20px 15px 50px;
        margin: 0 auto;
        position: absolute;
        top: 80px;
        left: 0;
        right: 0;
    }
    .thsb_cp_swap_product_model_inner {
        width: 100%;
        float: left;
        position: relative;
        background: #fff;
        padding: 20px;
        border-radius: 8px;
    }
    .thsb_cp_swap_product_model_close_btn {
        position: absolute;
        right: 5px;
        top: 5px;
        width: 15px;
        height: 15px;
        cursor: pointer;
        padding: 5px 0px 0 20px;
        &:before {
            position: absolute;
            left: 9px;
            content: ' ';
            height: 15px;
            width: 2px;
            background-color: $background-color_4;
            transform: rotate(45deg);
        }
        &:after {
            position: absolute;
            left: 9px;
            content: ' ';
            height: 15px;
            width: 2px;
            background-color: $background-color_4;
            transform: rotate(-45deg);
        }
    }
    .thsb_cp_swap_product_model_search_product {
        padding: 0;
        margin: 0 0 15px;
        label {
            width: 100%;
            float: left;
            font-size: 14px;
            line-height: normal;
            padding: 0 0 3px;
            margin: 0;
        }
        .thsb_cp_swap_product_model_input_cls {
            width: 100%;
            float: left;
            padding: 5px;
            max-width: initial;
            min-height: inherit;
            font-size: 14px;
            line-height: normal;
            margin: 0;
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
    .thsb_cp_swap_product_listing {
        padding: 0;
        margin: 0;
        ul {
            padding: 0;
            margin: 0;
            list-style: none !important;
            li {
                margin: 0;
                padding: 10px 0;
                list-style-type: none !important;
                border-top: 1px solid #eee;
                &:last-child {
                    border-bottom: 1px solid #eee;
                }
            }
        }
    }
    .thsb_cp_swap_product_listing_section {
        margin: 0;
        padding: 0;
        .thsb_cp_swap_product_listing_main_detail {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            align-items: center;
            margin: 0;
            padding: 0;
        }
        figure {
            -ms-flex-preferred-size: 0;
            flex-basis: 0;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            max-width: 70px;
            margin: 0;
            padding: 5px;
            border: 1px solid #eee;
            img {
                float: left;
                max-width: 100%;
            }
        }
        .thsb_cp_swap_product_title {
            -ms-flex-preferred-size: 0;
            flex-basis: 0;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            max-width: 100%;
            padding: 0 0 0 15px;
            margin: 0;
            h6 {
                width: 100%;
                float: left;
                font-size: 16px;
                line-height: normal;
                margin: 0;
                padding: 0;
                word-break: break-word;
            }
            span {
                width: 100%;
                float: left;
                font-size: 14px;
                line-height: normal;
                padding: 5px 0 0;
                margin: 0;
            }
        }
        .thsb_cp_swap_product_add_section {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: auto;
            margin: 0;
            padding: 0 0 0 15px;
            text-align: right;
            .thsb_cp_swap_product_add_btn {
                margin: 0;
                padding: 8px 15px;
                text-align: center;
            }
        }
    }
    .thsb_cp_ajax_btn_cls {
        position: relative;
        span {
            width: auto;
            float: left;
            word-break: break-word;
            white-space: break-spaces;
            font-size: 14px;
            line-height: normal;
            margin: 0;
            padding: 0;
        }
    }
    .thsb_cp_ajax_btn_cls.thsb_cp_ajax_btn_cls_loading {
        span.thsb_cp_btn_text {
            opacity: 0;
        }
        em.thsb_cp_btn_loader {
            width: 15px;
            height: 15px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -8px 0 0 -8px;
            opacity: 1;
            transition-timing-function: ease-in;
            -webkit-animation: ld-cycle 1s infinite linear;
            animation: ld-cycle 1s infinite linear;
            &::after {
                width: 100%;
                height: 100%;
                position: absolute;
                margin: auto;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                content: " ";
                display: inline-block;
                background: center center no-repeat;
                background-size: cover;
                -webkit-background-clip: padding-box;
                box-sizing: border-box;
                border-width: 2px;
                border-color: $border-color_1;
                border-radius: 50%;
                border-style: solid;
            }
        }
    }
}
.thsb_cp_swap_product_model.thsb_csa_swap_model_show {
    opacity: 1;
    z-index: 999999999999;
}
.thsb_cp_add_product_qty {
    h6 {
        width: 100%;
        float: left;
        font-size: 16px;
        line-height: normal;
        margin: 0 0 6px 0;
        padding: 0;
        word-break: break-word;
    }
    .wc_add_product_qty {
        &:focus-visible {
            outline: none;
            box-shadow: none;
        }
    }
}
.thsb_cp_add_product_price {
    h6 {
        width: 100%;
        float: left;
        font-size: 16px;
        line-height: normal;
        margin: 0 0 6px 0;
        padding: 0;
        word-break: break-word;
    }
}
.wc_add_product_button_div {
    .wc_add_product_button_submit {
        width: 100%;
        line-height: normal;
        margin-right: 13px;
        border-radius: $border-radius;
    }
    .wc_add_item_as_button_cancel {
        width: 100%;
        padding: 8px 15px;
        font-size: 14px;
        line-height: normal;
        cursor: pointer;
        border-radius: $border-radius;
    }
    float: right;
    display: flex;
}
.wc_add_product_button_submit {
    .thsb_cp_btn_text {
        word-break: unset !important;
    }
}
.wc_add_product_btn {
    width: 100%;
    font-size: 14px;
    line-height: normal;
    min-width: 180px;
    border-radius: $border-radius;
}
.wc_footer_div {
    .previous_next_btn_div {
        display: flex;
        flex-wrap: wrap;
        .wc_pager {
            display: flex;
        }
    }
}
.thsb_cp_add_products_title {
    padding-top: 30px !important;
}
.thsb_cp_add_products {
    display: inline-block;
    width: 100%;
    margin: 0;
    .thsb_cp_add_product_ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: -5px;
        padding: 0;
    }
}
.thsb_cp_add_product_ul {
    .cards__item {
        display: flex;
        padding: 5px;

        @media only screen and (max-width: 639px) {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}
.thsb_cp_add_product_card {
    background-color: white;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    .card__content {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        padding: 1rem;
    }
    .card__image {
        background-position: center center;
        background-size: cover;
        padding: 8px;
        border-radius: 12px;
        &:before {
            content: "";
            display: block;
            padding-top: 56.25%;
            // 16: 9 aspect ratio;
        }
    }
    .card__title {
        flex: 1 1 auto;
        margin-bottom: 1.25rem;
    }
    .card__btn {
        .thsb_cp_btn_text {
            float: none;
        }
        &:hover {
            cursor: pointer;
        }
    }
    .thsb_variant_error {
        color: red;
        margin-top: 10px;
        text-align: center;
    }
}
.thsb_add_product_search_div {
    margin: 0 0 15px;
    .thsb_add_product_search_title {
        width: 100%;
        float: left;
        line-height: normal;
        padding: 0 0 3px;
    }
    .thsb_add_product_search_input {
        width: -webkit-fill-available;
        padding: 5px;
        font-size: 14px;
        line-height: normal;
        border-radius: $border-radius;
    }
}
.thsb_cp_next_order_note_div {
    .thsb_sp_next_order_text {
        width: 100%;
        border-radius: 4px;
    }
}
.thsb_sp_order_note_error_div {
    margin: 10px 10px 10px 0;
}
.thsb_cp_s_re_order {
    margin: auto;
    margin-right: 10px;
}
.thsb_cp_change_full_fieldset {
    >.thsb_cp_change_date_half_padding {
        width: 50%;
        float: left;
        padding-left: 10px;
        &:nth-child(odd) {
            padding-right: 10px;
            padding-left: 0;
        }
    }
}
@media screen and (max-width: 1024px) {
    .thsb_cp_detail_s_details {
        .thsb_cp_detail_s_discount_input {
            width: 100%;
        }
        .thsb_cp_detail_s_discount_btn {
            width: 100%;
            margin: 10px 0 0;
            text-align: center;
            span {
                float: none;
                display: inline-block;
            }
        }
    }
}
@media screen and (max-width: 991px) {
    .thsb_cp_detail_s_details {
        .thsb_cp_detail_s_no_btn_attempt {
            flex: 0 0 100%;
            max-width: 100%;
        }
        .thsb_cp_detail_s_no_btn_skip {
            flex: 0 0 100%;
            max-width: 100%;
        }
        .thsb_cp_detail_s_no_btn_pause {
            flex: 0 0 100%;
            max-width: 100%;
            margin: 0 0 10px;
        }
        .thsb_cp_detail_s_no_btn_cancel {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .thsb_cp_detail_s_products {
        .thsb_cp_detail_sp_detail_inner {
            .thsb_cp_detail_sp_btn_remove {
                margin: 10px 0 0;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .thsb_cp_detail_section {
        .thsb_cp_detail_page_title {
            padding-bottom: 20px;
        }
        .thsb_cp_detail_s_details {
            flex: 0 0 100%;
            max-width: 100%;
        }
        .thsb_cp_detail_s_products {
            flex: 0 0 100%;
            max-width: 100%;
            margin: 20px 0 0;
        }
    }
    .thsb_cp_detail_s_details {
        .thsb_cp_detail_s_title {
            h4 {
                line-height: normal;
            }
        }
        .thsb_cp_detail_s_no_btn_attempt {
            flex: 0 0 50%;
            max-width: 50%;
        }
        .thsb_cp_detail_s_no_btn_skip {
            flex: 0 0 50%;
            max-width: 50%;
        }
        .thsb_cp_detail_s_no_btn_pause {
            flex: 0 0 50%;
            max-width: 50%;
            margin: 0;
        }
        .thsb_cp_detail_s_no_btn_cancel {
            flex: 0 0 50%;
            max-width: 50%;
        }
        .thsb_cp_detail_s_discount_input {
            width: auto;
        }
        .thsb_cp_detail_s_discount_btn {
            width: auto;
            margin: 0 0 0 10px;
        }
        .thsb_cp_detail_s_no_date {
            h4 {
                font-size: 20px;
            }
        }
        .thsb_cp_detail_s_de_days {
            h4 {
                font-size: 20px;
            }
        }
    }
    .thsb_cp_detail_s_products {
        .thsb_cp_detail_s_p_title {
            h4 {
                line-height: normal;
            }
        }
        .thsb_cp_detail_sp_detail_inner {
            .thsb_cp_detail_sp_btn_remove {
                margin: 0;
            }
        }
    }
    .thsb_cp_subscription_tab_contants {
        .thsb_cp_s_id {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 5px 0 0;
            order: 1;
        }
        .thsb_cp_s_product_details {
            flex: 0 0 60%;
            max-width: 60%;
            padding: 0 5px 0 0;
            order: 3;
            margin: 15px 0 0;
        }
        .thsb_cp_s_next_order {
            flex: 0 0 40%;
            max-width: 40%;
            padding: 0 0 0 5px;
            order: 4;
            text-align: right;
        }
        .thsb_cp_s_manage_column {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 0 0 5px;
            order: 2;
        }
        .thsb_cp_s_product_details_inner {
            .thsb_cp_s_product_title {
                a {
                    font-size: 14px;
                }
            }
        }
        .thsb_cp_s_next_order_date {
            font-size: 14px;
        }
    }
}
@media screen and (max-width: 641px) {
    .thsb_cp_detail_s_products {
        .thsb_cp_detail_sp_detail_inner {
            .thsb_cp_detail_sp_qty_update {
                flex: 0 0 100%;
                max-width: 100%;
            }
            .thsb_cp_detail_sp_btn_update {
                margin: 10px 0 0;
            }
            .thsb_cp_detail_sp_btn_remove {
                margin: 10px 0 0;
            }
        }
    }
}
@media screen and (max-width: 481px) {
    .thsb_cp_detail_s_products {
        .thsb_cp_detail_sp_detail_inner {
            .thsb_cp_detail_sp_btn_update {
                flex: 0 0 100%;
                max-width: 100%;
            }
            .thsb_cp_detail_sp_btn_remove {
                flex: 0 0 100%;
                max-width: 100%;
            }
            .thsb_cp_detail_sp_update_qty_btn {
                width: 100%;
            }
            .thsb_cp_detail_sp_remove_qty_btn {
                width: 100%;
            }
            .thsb_cp_ajax_btn_cls {
                span {
                    float: none;
                    display: inline-block;
                }
            }
        }
        .thsb_cp_detail_s_product_shipping_text {
            font-size: 14px;
        }
        .thsb_cp_detail_s_product_shipping_value {
            font-size: 14px;
        }
        .thsb_cp_detail_s_product_total_price {
            font-size: 14px;
        }
        .thsb_cp_detail_s_product_status_text {
            font-size: 14px;
        }
        .thsb_cp_detail_s_product_status_value {
            font-size: 14px;
        }
        .thsb_cp_detail_s_ba_title {
            h4 {
                font-size: 14px;
            }
        }
    }
    .thsb_cp_detail_s_details {
        .thsb_cp_detail_s_discount_input {
            width: 100%;
        }
        .thsb_cp_detail_s_discount_btn {
            width: 100%;
            margin: 10px 0 0;
        }
    }
}
@media screen and (min-width: 40rem) {
    .thsb_cp_add_product_ul {
        .cards__item {
            width: 33.33%;
        }
    }
    .thsb_cp_add_product_card {
        .card__image {
            &:before {
                padding-top: 66.6%;
            }
        }
    }
}

a.th_subscription_cp_link {
    display: block;
}
